import { Box, Card, styled, SvgIcon, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';

export const StyledHeader = styled(Typography)(() => ({
    fontFamily: 'Inter Tight',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    zIndex: 11,
}));

export const StyledHeaderSans = styled(Typography)(() => ({
    fontFamily: 'Open Sans',
    fontWeight: '400',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.mode === 'dark' ? grey[500] : grey[700],
}));

export const StyledLinkColor = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
}));

export const StyledLinkColorNoUnderline = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '10px',
    background: theme.palette.mode === 'dark' ? 'rgb(24, 26, 27)' : theme.palette.background.paper,
}));

export const StyledSvgIcon = styled(SvgIcon, { shouldForwardProp: (propName) => propName !== 'selected' })<{ selected?: boolean }>(
    ({ theme, selected }) => ({
        fill: theme.palette.svgIcon.default,
        ...(selected && {
            fill: '#fff',
        }),
    }),
);
