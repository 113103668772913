import React from 'react';
import { IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledLinkColorNoUnderline } from '../../../components/Styled/Styled.components';

interface UsernameLabelProps {
    user: { displayName: string; banned: boolean; deleted: boolean; id: string; identityVerified?: boolean };
}

export const UsernameLabel: React.FC<UsernameLabelProps> = ({ user }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (user.deleted) {
        return (
            <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                DELETED
            </Typography>
        );
    }

    if (user.banned) {
        return (
            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                {user.displayName}
            </Typography>
        );
    }

    return (
        <Stack direction="row" alignItems="center">
            <StyledLinkColorNoUnderline sx={{ minWidth: 0, padding: 0 }} to={`/user/${user.id}`}>
                {user.displayName}
            </StyledLinkColorNoUnderline>

            {user.identityVerified && (
                <Tooltip title={t('hasCompletedSCA')}>
                    <IconButton sx={{ ml: '8px', p: 0 }}>
                        <VerifiedUser fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};
