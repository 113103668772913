/* eslint-disable import/no-duplicates */
import {
    addMinutes,
    differenceInDays,
    differenceInHours,
    format,
    formatDistance,
    formatDistanceToNow,
    formatISO,
    getDate,
    getMonth,
    getYear,
    isAfter,
    parseISO,
    set,
} from 'date-fns';
import { enGB, fi } from 'date-fns/locale';
import i18next from 'i18next';
import { assertNever } from './commonHelpers';
import { logger } from './logger';

export type NullableDateLike = string | Date | undefined | null;

export const getCurrentLocale = () => {
    const currentLang = i18next.language || window.localStorage.i18nextLng || '';
    if (currentLang === 'fi') {
        return fi;
    }
    if (currentLang === 'en') {
        return enGB;
    }
    return enGB;
};

function formatDate(date: NullableDateLike, dateFormat: string): string {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return format(parseISO(date), dateFormat, { locale: getCurrentLocale() });
    }

    if (date instanceof Date) {
        return format(date, dateFormat, { locale: getCurrentLocale() });
    }

    return assertNever(date);
}

export const formatDateAndTimeLong = (date: NullableDateLike): string => formatDate(date, 'PPp');

// e.g "10:00"
export const formatTime = (date: NullableDateLike): string => formatDate(date, 'HH:mm');

// e.g "Mon 11:38"
export const formatShortDateAndTime = (date: NullableDateLike): string => formatDate(date, 'ccc HH:mm');

// e.g "May 15"
export const formatMonthAndDate = (date: NullableDateLike): string => formatDate(date, 'MMMM d');

// e.g "15 May 2020"
export const formatDayMonthAndYear = (date: NullableDateLike): string => formatDate(date, 'PPPP');

// e.g "Monday, May 15 January"
export const formatDayMonthAndDate = (date: NullableDateLike): string => formatDate(date, 'EEE, d MMMM');

// e.g "Nov 15"
export const formatShortMonthAndDate = (date: NullableDateLike): string => formatDate(date, 'MMM d');

// e.g "Nov 15, 10:00"
export const formatShortMonthAndDateAndTime = (date: NullableDateLike): string => formatDate(date, 'MMM d, HH:mm');

// e.g 10/05/2020, 23:10
export const formatLongDateAndTime = (date: NullableDateLike): string => formatDate(date, 'P, HH:mm');

// e.g "May 15, 2020"
export const formatLongDateAndYear = (date: NullableDateLike): string => formatDate(date, 'PP');

// e.g "Monday, May 15, 23:10"
export const formatDayOfWeekAndMonthAndDateAndTime = (date: NullableDateLike): string => formatDate(date, 'EEEE, MMMM d, HH:mm');

// e.g Monday, May 15, 23:10 - 23:40
export const formatDateAndTimeRange = (d: NullableDateLike, interval: number): string => {
    if (!d) {
        return '';
    }

    const date = typeof d === 'string' ? parseISO(d) : d;
    return `${formatDayOfWeekAndMonthAndDateAndTime(date)} - ${formatTime(addMinutes(date, interval))}`;
};

export const formatTimeRange = (d: NullableDateLike, durationInMinutes = 30) => {
    if (!d) {
        return '';
    }

    const date = typeof d === 'string' ? parseISO(d) : d;

    return `${formatTime(date)} - ${formatTime(addMinutes(date, durationInMinutes))}`;
};

// Note: use only with hours in format "HH:mm"
export const formatHHMMTimeRange = (hhmm: string, durationInMinutes = 30) => {
    if (hhmm.length !== 5) {
        return '';
    }

    const d = new Date();
    const [hours, minutes] = hhmm.split(':').map(Number);
    d.setHours(hours, minutes, 0, 0);

    return formatTimeRange(d, durationInMinutes);
};

// Note: accepts only hours in format "HH:mm:ss"
export const formatHoursAndMinutes = (hour: string) => {
    return hour.split(':').slice(0, 2).join(':');
};

export const getHoursDifference = (date: string, compareDate: string): number => differenceInHours(new Date(date), new Date(compareDate));

export const getDaysDifference = (date: string, compareDate: string): number => differenceInDays(new Date(date), new Date(compareDate));

export const getDurationInDays = (startDate: string, endDate: string): number => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    return differenceInDays(end, start) + 1;
};

export const isDifferentDate = (date: string, compareDate: string): boolean => {
    const d1 = formatISO(parseISO(date), { representation: 'date' });
    const d2 = formatISO(parseISO(compareDate), { representation: 'date' });

    return d1 !== d2;
};

export const formatFlexibleDateTime = (date: string): string => {
    const now = new Date();
    const compareDate = new Date(date);

    const daysDifference = differenceInDays(now, compareDate);

    if (daysDifference < 1) {
        return formatTime(date);
    }
    if (daysDifference >= 1 && daysDifference <= 7) {
        return formatShortDateAndTime(date);
    }
    if (daysDifference > 7 && daysDifference <= 365) {
        return formatMonthAndDate(date);
    }
    return formatLongDateAndTime(date);
};

export const formatDistanceLocalized = (d1: Date, d2: Date) => formatDistance(d1, d2, { locale: getCurrentLocale() });
export const formatDistanceNow = (date: string): string => formatDistanceToNow(new Date(date), { locale: getCurrentLocale() });

export const convertToMidnightUTC = (referenceDate: Date) => {
    const year = getYear(referenceDate);
    const month = getMonth(referenceDate);
    const date = getDate(referenceDate);

    const midnightUTC = new Date(Date.UTC(year, month, date, 0, 0, 0));

    return midnightUTC;
};

export const getLaterDate = (d1: Date | null, d2: Date | null) => {
    if (!d1) {
        return d2;
    }
    if (!d2) {
        return d1;
    }
    return isAfter(d1, d2) ? d1 : d2;
};

export const parseDateStringUTC = (d: string | undefined) => {
    if (!d) {
        return undefined;
    }

    const parsedDate = parseISO(d);
    const utcDate = set(parsedDate, { year: parsedDate.getUTCFullYear(), month: parsedDate.getUTCMonth(), date: parsedDate.getUTCDate() });
    return utcDate;
};
