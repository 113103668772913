import React from 'react';
import { StripeAccountDataWithStatus } from '../../views/UserProfile/hooks/useStripeAccount';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, Button, Typography } from '@mui/material';
import { StyledLinkColor } from '../Styled/Styled.components';
import { hasRequirements } from '../../views/UserProfile/Stripe/helpers';
import { formatDayMonthAndYear } from '../../helpers/dateAndTimeHelpers';
import { fromUnixTime } from 'date-fns';

interface StripeAccountAlertMaybeProps {
    stripeConnected: boolean | undefined;
    stripeAccount: StripeAccountDataWithStatus | undefined;
    hideButton?: boolean;
    showDescription?: boolean;
    deadline?: number;
    onClick?: () => void;
}

export const StripeAccountAlertMaybe: React.FC<StripeAccountAlertMaybeProps> = ({
    stripeConnected,
    stripeAccount,
    hideButton,
    showDescription,
    deadline,
    onClick,
}) => {
    const { t } = useTranslation();

    if (stripeAccount === undefined) {
        return null;
    }

    const { payoutsDisabled } = stripeAccount;

    if (!stripeConnected) {
        return (
            <Alert severity="error" sx={{ mb: 3, borderRadius: '10px' }}>
                {t('stripeNotConnectedError')}

                {!hideButton && (
                    <Button fullWidth variant="text" sx={{ textTransform: 'none', px: 0, pt: 2, justifyContent: 'start' }} onClick={onClick}>
                        {t('stripeNotConnectedButtonTitle')}
                    </Button>
                )}
            </Alert>
        );
    }

    if (hasRequirements(stripeAccount, ['currently_due'])) {
        return (
            <>
                <Alert severity="warning" sx={{ mb: 3, borderRadius: '10px' }}>
                    {t('stripeFutureRequirementsWarning')}
                    {!hideButton && (
                        <Button fullWidth variant="text" sx={{ textTransform: 'none', px: 0, pt: 2, justifyContent: 'start' }} onClick={onClick}>
                            {t('verifyMyIdentity')}
                        </Button>
                    )}
                </Alert>

                {hideButton && showDescription && (
                    <Box sx={{ pb: 3 }}>
                        {deadline && (
                            <Typography variant="body2">
                                <Trans
                                    i18nKey="fillInformationByDeadline"
                                    values={{ deadline: formatDayMonthAndYear(fromUnixTime(deadline)) }}
                                    components={{
                                        h3: <h3 style={{ marginTop: '0' }} />,
                                    }}
                                />
                            </Typography>
                        )}

                        <Typography variant="body2">
                            <Trans
                                i18nKey="stripeRequirementsDescription"
                                components={{
                                    b: <b />,
                                    i: <i />,
                                    h3: <h3 style={{ marginTop: '0' }} />,
                                    h4: <h4 />,
                                    h6: <h6 />,
                                    identityVerificationLink: (
                                        <StyledLinkColor
                                            target="_blank"
                                            rel="noopener"
                                            to="https://support.stripe.com/questions/documents-for-identity-and-home-address-verification"
                                        />
                                    ),
                                    KYCLink: (
                                        <StyledLinkColor
                                            target="_blank"
                                            rel="noopener"
                                            to="https://support.stripe.com/questions/know-your-customer-obligations"
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    </Box>
                )}
            </>
        );
    }

    if (payoutsDisabled) {
        return (
            <>
                <Alert severity="error" sx={{ mb: 3, borderRadius: '10px' }}>
                    {t('stripePayoutsDisabledError')}{' '}
                    {!hideButton && (
                        <Button fullWidth variant="text" sx={{ textTransform: 'none', px: 0, pt: 2, justifyContent: 'start' }} onClick={onClick}>
                            {t('verifyMyIdentity')}
                        </Button>
                    )}
                </Alert>

                {hideButton && showDescription && (
                    <Box sx={{ pb: 3 }}>
                        {deadline && (
                            <Typography variant="body2">
                                <Trans
                                    i18nKey="fillInformationByDeadline"
                                    values={{ deadline }}
                                    components={{
                                        h3: <h3 style={{ marginTop: '0' }} />,
                                    }}
                                />
                            </Typography>
                        )}

                        <Typography variant="body2">
                            <Trans
                                i18nKey="stripeRequirementsDescription"
                                components={{
                                    b: <b />,
                                    i: <i />,
                                    h3: <h3 style={{ marginTop: '0' }} />,
                                    h4: <h4 />,
                                    h6: <h6 />,
                                    identityVerificationLink: (
                                        <StyledLinkColor
                                            target="_blank"
                                            rel="noopener"
                                            to="https://support.stripe.com/questions/documents-for-identity-and-home-address-verification"
                                        />
                                    ),
                                    KYCLink: (
                                        <StyledLinkColor
                                            target="_blank"
                                            rel="noopener"
                                            to="https://support.stripe.com/questions/know-your-customer-obligations"
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    </Box>
                )}
            </>
        );
    }

    return null;
};
