import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { fetchProfile, getOnboardingFlowUrl } from '../../helpers/authHelpers';
import { authApiClient } from '../../services/sharetribe/apiClients';
import { useAppDispatch } from '../../store/hooks';
import { storeToken } from '../../store/userReducer';
import { LoginSuccess, User, UserData } from '../../types/apiTypes';
import { Preferences } from '@capacitor/preferences';
import { useSafeNavigate } from '../useSafeNavigate';
import { Capacitor } from '@capacitor/core';

interface FormData {
    email: string;
    password: string;
}

type LoginSuccessResponse = LoginSuccess;

export const useLogin = (): UseMutationResult<LoginSuccessResponse, unknown, FormData> => {
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();

    const { t } = useTranslation();

    const loginFn = async (formData: FormData) => {
        const url = '/login';
        const { data } = await authApiClient.post<LoginSuccessResponse>(url, formData);

        return data;
    };

    const queryClient = useQueryClient();

    return useMutation(loginFn, {
        onSuccess: async (res) => {
            if (!('access_token' in res)) {
                throw new Error('malformed access token response');
            }

            const token = JSON.stringify(res);
            dispatch(storeToken(token));

            const { response } = await fetchProfile(token);

            if (response) {
                queryClient.setQueryData(['current-user'], () => response.data);

                if (response.data.emailVerified) {
                    navigate(getOnboardingFlowUrl(response.data), { replace: true });
                } else {
                    // If pendingToken exists, user was redirected to login from email verification page due to inactive session
                    const { value: pendingToken } = await Preferences.get({ key: 'pendingVerificationToken' });

                    if (pendingToken) {
                        try {
                            await authApiClient.post('/verify-email', { token: pendingToken });

                            queryClient.setQueryData<User | undefined>(['current-user'], (prev) => {
                                if (!prev) {
                                    return undefined;
                                }
                                return { ...prev, emailVerified: true };
                            });

                            navigate('/', { replace: true });
                        } catch (err) {
                            // pending verification token has expired or is invalid
                            const url = '/send-verification-email';
                            await authApiClient.post(url);

                            navigate('/verification-pending');
                        }

                        await Preferences.remove({ key: 'pendingVerificationToken' });
                    } else {
                        navigate('/verification-pending');
                    }
                }
            }
        },

        onError: async () => {
            // TODO: Proper error handling
            const msg = t('invalidCredentials');
            toast.error(msg, { toastId: 'invalid-credentials' });
        },
    });
};
