import React, { useCallback } from 'react';
import { User } from '../../types/apiTypes';
import { useListing } from '../../queries/useListing';
import { ReactFCWithChildren } from '../../types/types';
import { useCurrentUser } from '../../user/hooks/useUser';
import { ProfileRowInfo } from './ProfileRowInfo';
import { ProfileRowBio } from './ProfileRowBio';

import { useChatWindow } from '../../context/chat';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';

export interface ProfileInfoProps {
    userData: User;
    allowEditing?: boolean;
    listingId?: string;
    showBio?: boolean;
    pictureSize?: 'small' | 'medium';
    enableFullScreen?: boolean;
}
export const ProfileSection: ReactFCWithChildren<ProfileInfoProps> = ({
    userData,
    listingId,
    allowEditing = true,
    showBio = false,
    pictureSize = 'medium',
    enableFullScreen = false,
}) => {
    const { data: loggedInUser } = useCurrentUser();
    const isOwnUser = userData && userData.id === loggedInUser?.id;
    const { data: listing } = useListing(listingId, { own: isOwnUser, enabled: !!loggedInUser });

    const navigate = useSafeNavigate();

    const { openChat, closeChat } = useChatWindow();

    const handleClickUser = useCallback((userId: string) => {
        closeChat();
        navigate(`/user/${userId}`);
    }, []);

    const handleChatIconClick = () => {
        const participant = { displayName: userData.profile.displayName, profileImage: userData.profileImage, id: userData.id };
        const chatProps = { participant, listing, onClickUser: handleClickUser };

        openChat(chatProps);
    };

    const resolvedUser = isOwnUser ? loggedInUser : userData;

    if (!resolvedUser) {
        return null;
    }

    return (
        <>
            <ProfileRowInfo
                allowEditing={allowEditing}
                userData={resolvedUser}
                isOwnUser={isOwnUser}
                handleChatIconClick={handleChatIconClick}
                pictureSize={pictureSize}
                enableFullScreen={enableFullScreen}
            />

            <ProfileRowBio userData={resolvedUser} show={showBio} />
        </>
    );
};
