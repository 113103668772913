import { cloneDeep, isArray, isEqual, isNumber } from 'lodash';
import { initialState } from '../store/listingsReducer';
import { Filter } from '../views/Listings/constants';
import { ListingsState, FilterQueryStringMap } from '../views/Listings/types';
import { isNil } from './commonHelpers';
import { maxPriceFilter } from '../constants';

export const makeQueryString = (params: Readonly<Record<string, string | string[] | null | undefined>>): string => {
    const urlSearchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (!isNil(value) && value !== '') {
            if (isArray(value)) {
                value.forEach((val) => {
                    urlSearchParams.append(key, val);
                });
            } else {
                urlSearchParams.append(key, value);
            }
        }
    });

    const queryStr = urlSearchParams.toString();

    return queryStr.length > 0 ? queryStr : '';
};

export const selectOptionsToQueryString = <T extends { selected: boolean; value: string }>(options: T[]): string =>
    options
        .filter((option) => option.selected)
        .map((option) => option.value)
        .join(',');

export const filtersToQueryString = (state: ListingsState['filters']): string => {
    const filterValueToQueryString = (value: Filter) => {
        switch (value) {
            case 'keywords':
                return state.keywords;
            case 'color':
                return state.color ? state.color.join(',') : null;
            case 'size':
                return state.size ? state.size.join(',') : null;
            case 'brand':
                return state.brand ? state.brand.join(',') : null;
            case 'category':
                return state.category ? state.category.join(',') : null;
            case 'bodytype':
                return state.bodytype ? state.bodytype.join(',') : null;
            case 'delivery':
                return state.delivery ? state.delivery.join(',') : null;
            case 'price': {
                if (!state.price) {
                    return null;
                }
                const defaultPriceRange = initialState.filters.price;

                if (isEqual(defaultPriceRange, state.price)) {
                    return null;
                }

                const [min, max] = state.price;

                if (max === maxPriceFilter) {
                    return `${min * 100},`;
                }

                return state.price.map((price) => (price * 100).toString()).join(',');
            }
            default:
                return null;
        }
    };

    const filterKeys = Object.keys(state) as Filter[];

    const queryStringMap = filterKeys.reduce((acc, curr: Filter) => {
        const convertedValue = filterValueToQueryString(curr);
        acc[curr] = convertedValue;
        return acc;
    }, <FilterQueryStringMap>{});

    return makeQueryString(queryStringMap);
};

export const queryStringToFilters = (queryString: string): ListingsState['filters'] => {
    const { filters } = initialState;
    const filtersCopy = cloneDeep(filters);

    const filterValueToQueryString = <K extends keyof ListingsState['filters']>(key: K, value: string) => {
        switch (key) {
            case 'keywords':
                return value;
            case 'color':
            case 'size':
            case 'brand':
            case 'category':
            case 'delivery':
            case 'bodytype':
                return value.split(',');
            case 'price': {
                const priceRange = value.split(',');

                if (priceRange?.length !== 2) {
                    return filters.price;
                }

                return priceRange.map((val) => parseInt(val, 10) / 100).filter((num) => isNumber(num));
            }
            default:
                return null;
        }
    };
    const { filters: defaultFilters } = initialState;

    const filterKeys = Object.keys(defaultFilters) as (keyof typeof defaultFilters)[];
    const params = new URLSearchParams(queryString);

    filterKeys.forEach((key) => {
        const value = params.get(key);
        if (!value) {
            return;
        }
        const decodedValue = decodeURIComponent(value);

        if (decodedValue === filters[key]) {
            return;
        }
        const convertedValue = filterValueToQueryString(key, decodedValue);
        if (!convertedValue) {
            return;
        }
        filtersCopy[key] = convertedValue as any;
    });

    return filtersCopy;
};

export const sortByToQueryString = (sortBy: string): string => (sortBy ? `sort=${sortBy}` : '');

export const queryStringToSortBy = (queryString: string): string => {
    const params = new URLSearchParams(queryString);
    return params.get('sort') || 'createdAt';
};

export const queryString2State = (queryString: string): { filters: ListingsState['filters']; sortBy: string } => {
    const filters = queryStringToFilters(queryString);
    const sortBy = queryStringToSortBy(queryString);
    return { filters, sortBy };
};

export const state2QueryString = (state: ListingsState, selectedCategory?: string): string => {
    const { sortBy, filters } = state;
    const filterParams = filtersToQueryString(filters);
    const sortByParams = sortByToQueryString(sortBy);
    const params = [filterParams, sortByParams].filter((el) => el).join('&');

    return selectedCategory ? `?${params}&selectedCategory=${selectedCategory}` : `?${params}`;
};

export const replaceUrlParams = (params: string): void => window.history.replaceState('', document.title, `${window.location.pathname}${params}`);
