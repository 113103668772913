import { StripeAccount } from '../../../transactions/apiTypes';

type Requirement = 'currently_due' | 'past_due' | 'eventually_due';

export const hasRequirements = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined, requirements: Requirement[]) => {
    if (!stripeAccountData) {
        return false;
    }

    return requirements.some(
        (requirementType) =>
            stripeAccountData &&
            stripeAccountData.requirements &&
            Array.isArray(stripeAccountData.requirements[requirementType]) &&
            // @ts-ignore
            stripeAccountData.requirements[requirementType].length > 0,
    );
};
